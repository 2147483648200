body {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: #f9f9f9;
  color: #333;
}

header {
  background: transparent;
  color: white;
  padding: 1em 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  width: 100%;
}

nav {
  margin-right: 2em;
}

nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
}

nav ul li {
  margin-left: 20px;
}

nav ul li a {
  color: white;
  text-decoration: none;
  font-weight: bold;
  font-size: 1.1em;
}

.hero {
  background: url('/public/background-home.jpg') no-repeat center center/cover;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  padding: 20px;
  box-sizing: border-box;
}

.hero h2 {
  font-size: 4em;
  margin-bottom: 0.5em;
}

.hero p {
  font-size: 1.5em;
  margin-bottom: 1.5em;
}

.btn {
  background: #007bff;
  color: white;
  border: none;
  padding: 1em 2em;
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
  margin-top: 1em;
  border-radius: 5px;
  transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
  font-size: 1.1em;
}

.btn:hover {
  background: #0056b3;
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}

.section {
  padding: 3em 0;
  background-color: white;
  margin-bottom: 2em;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
}

.features {
  text-align: center;
}

.features h2 {
  margin-bottom: 1em;
}

.features .feature-item {
  display: inline-block;
  width: 30%;
  margin: 0 1.5%;
  padding: 1.5em;
  background-color: #f1f1f1;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.features .feature-item:hover {
  transform: translateY(-10px);
}

.features .feature-item h3 {
  margin-bottom: 0.5em;
  color: #007bff;
}

.features .feature-item p {
  margin-bottom: 1em;
}

.pricing {
  text-align: center;
}

.pricing h2 {
  margin-bottom: 1em;
}

.pricing .pricing-plan {
  display: inline-block;
  width: 30%;
  margin: 0 1.5%;
  padding: 1.5em;
  background-color: #f1f1f1;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.pricing .pricing-plan:hover {
  transform: translateY(-10px);
}

.pricing .pricing-plan h3 {
  margin-bottom: 0.5em;
  color: #007bff;
}

.pricing .pricing-plan p {
  margin-bottom: 1em;
}

.pricing .pricing-plan ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

footer {
  background: #007bff;
  color: white;
  padding: 1.5em 0;
  text-align: center;
  margin-top: 0;
}

footer nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  margin-bottom: 1em;
}

footer nav ul li {
  margin: 0 10px;
}

footer nav ul li a {
  color: white;
  text-decoration: none;
  font-size: 1em;
}

.contact {
  padding: 3em 0;
  background: #007bff;
  color: white;
  text-align: center;
}

.contact-form {
  background: white;
  padding: 2.5em;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  max-width: 700px;
  margin: 3em auto 0;
  color: #333;
  text-align: left;
}

.contact-form h2 {
  margin-bottom: 1em;
}

.contact-form .form-group {
  margin-bottom: 1.5em;
}

.contact-form label {
  display: block;
  margin-bottom: 0.5em;
  font-weight: bold;
}

.contact-form input,
.contact-form textarea {
  width: 100%;
  padding: 1em;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 1em;
}

.contact-form input:focus,
.contact-form textarea:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.contact-form textarea {
  height: 150px;
  resize: vertical;
}

.contact-info {
  display: flex;
  justify-content: center;
  margin-top: 2em; /* Adjust as needed */
}

.contact-info-item {
  display: flex;
  align-items: center;
  margin-right: 20px;
  background: #f1f1f1;
  padding: 10px 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  color: #333;
}

.contact-info-item .icon {
  font-size: 24px;
  margin-right: 10px;
}

@media (max-width: 768px) {
  .contact-info {
    flex-direction: column;
    align-items: center;
  }

  .contact-info-item {
    margin-bottom: 1em;
  }
}


.center {
  text-align: center;
}

.testimonials {
  text-align: center;
  padding: 3em 0;
  background-color: #fff;
  margin-bottom: 2em;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  position: relative;
}

.testimonials h2 {
  margin-bottom: 1em;
}

.testimonials .testimonial-item {
  padding: 1.5em;
  transition: transform 0.3s ease;
  background-color: #f1f1f1;
  border-radius: 12px;
  margin: 1em auto;
  max-width: 80%;
  position: relative;
}

.testimonials .testimonial-item:hover {
  transform: translateY(-10px);
}

.testimonials .conversation-icon {
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #007bff;
  color: white;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.testimonials .conversation-icon img {
  width: 80%;
  height: auto;
}

.cta {
  text-align: center;
  background-color: #007bff;
  color: white;
  padding: 3em 0;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  margin-bottom: 0;
}

.cta h2 {
  margin-bottom: 1em;
}

.cta p {
  margin-bottom: 1.5em;
}

.cta .btn {
  background: white;
  color: #007bff;
  border: 2px solid white;
  padding: 0.75em 1.5em;
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
  margin-top: 1em;
  border-radius: 25px;
  transition: background-color 0.3s ease, color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
  font-size: 1.1em;
}

.cta .btn:hover {
  background: #0056b3;
  color: white;
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}
.custom-dropdown {
  position: relative;
  display: inline-block;
  width: 100%;
}

.custom-dropdown select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  appearance: none; /* Remove default appearance */
  -webkit-appearance: none; /* Remove default appearance for Safari */
  -moz-appearance: none; /* Remove default appearance for Firefox */
  background-color: #fff;
  cursor: pointer;
  font-size: 1em;
}

.dropdown-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  pointer-events: none; /* Prevents icon from being clickable */
}

.dropdown-icon svg {
  width: 16px;
  height: 16px;
  fill: #333; /* Icon color */
  transition: transform 0.3s ease; /* Example of hover effect */
}

.custom-dropdown:hover .dropdown-icon svg {
  transform: translateY(-50%) rotate(180deg); /* Example of hover effect */
}






